import React from 'react'
import { useDevice } from 'device'
import { constants, cx } from 'helpers'
import { useAb } from 'hooks'

import { Carousel, Image, Text } from 'components/dataDisplay'

import desktopSlideB1Image from './images/b-desktop-1.jpg'
import desktopSlideB2Image from './images/b-desktop-2.jpg'
import desktopSlideB3Image from './images/b-desktop-3.jpg'
import desktopSlideB4Image from './images/b-desktop-4.jpg'
import desktopSlideB5Image from './images/b-desktop-5.jpg'
import mobileSlideB1Image from './images/b-mobile-1.jpg'
import mobileSlideB2Image from './images/b-mobile-2.jpg'
import mobileSlideB3Image from './images/b-mobile-3.jpg'
import mobileSlideB4Image from './images/b-mobile-4.jpg'
import mobileSlideB5Image from './images/b-mobile-5.jpg'

import desktopSlideC1Image from './images/c-desktop-1.jpg'
import desktopSlideC2Image from './images/c-desktop-2.jpg'
import desktopSlideC3Image from './images/c-desktop-3.jpg'
import desktopSlideC4Image from './images/c-desktop-4.jpg'
import desktopSlideC5Image from './images/c-desktop-5.jpg'
import mobileSlideC1Image from './images/c-mobile-1.jpg'
import mobileSlideC2Image from './images/c-mobile-2.jpg'
import mobileSlideC3Image from './images/c-mobile-3.jpg'
import mobileSlideC4Image from './images/c-mobile-4.jpg'
import mobileSlideC5Image from './images/c-mobile-5.jpg'

import desktopSlideD1Image from './images/d-desktop-1.jpg'
import desktopSlideD2Image from './images/d-desktop-2.jpg'
import desktopSlideD3Image from './images/d-desktop-3.jpg'
import desktopSlideD4Image from './images/d-desktop-4.jpg'
import desktopSlideD5Image from './images/d-desktop-5.jpg'
import mobileSlideD1Image from './images/d-mobile-1.jpg'
import mobileSlideD2Image from './images/d-mobile-2.jpg'
import mobileSlideD3Image from './images/d-mobile-3.jpg'
import mobileSlideD4Image from './images/d-mobile-4.jpg'
import mobileSlideD5Image from './images/d-mobile-5.jpg'

import messages from './messages'


const slidesAbMap = {
  'b': {
    mobile: [ mobileSlideB1Image, mobileSlideB2Image, mobileSlideB3Image, mobileSlideB4Image, mobileSlideB5Image ],
    desktop: [ desktopSlideB1Image, desktopSlideB2Image, desktopSlideB3Image, desktopSlideB4Image, desktopSlideB5Image ],
  },
  'c': {
    mobile: [ mobileSlideC1Image, mobileSlideC2Image, mobileSlideC3Image, mobileSlideC4Image, mobileSlideC5Image ],
    desktop: [ desktopSlideC1Image, desktopSlideC2Image, desktopSlideC3Image, desktopSlideC4Image, desktopSlideC5Image ],
  },
  'd': {
    mobile: [ mobileSlideD1Image, mobileSlideD2Image, mobileSlideD3Image, mobileSlideD4Image, mobileSlideD5Image ],
    desktop: [ desktopSlideD1Image, desktopSlideD2Image, desktopSlideD3Image, desktopSlideD4Image, desktopSlideD5Image ],
  },
}

const Slider: React.FunctionComponent = () => {
  const { isMobile } = useDevice()
  const sliderVersion = useAb(constants.abTests.homeHeroWithSlider)
  const slides = slidesAbMap[sliderVersion]?.[isMobile ? 'mobile' : 'desktop'] ?? []

  return (
    <Carousel
      className={isMobile ? '-mx-16' : 'absolute right-0 top-0 w-[811rem]'}
      containerClassName={isMobile ? '' : 'flex flex-col h-[768rem]'}
      autoplay
      loop
      slidesToScroll={1}
      skipSnaps={false}
      axis={isMobile ? 'x' : 'y'}
      dots={{
        className: cx('z-1 absolute left-1/2 !min-h-fit -translate-x-1/2', isMobile ? '-bottom-4' : 'bottom-40'),
        dotClassName: 'mt-0 mb-0 bg-white border-solid-gold-50',
        activeDotClassName: 'bg-gold-50',
      }}
      beforeDotsNode={sliderVersion === 'd' && (
        <Text
          className={
            cx('align-center z-1 absolute left-1/2 !min-h-fit -translate-x-1/2',
              isMobile ? 'bottom-[20rem]' : 'bottom-72'
            )
          }
          message={messages.greyFirstCase}
          style="p5"
        />
      )}
      withButtons={false}
      withDots
    >
      {
        slides.map((slide) => (
          <div>
            <Image
              className="w-full"
              src={slide}
              alt="Slide with fragrance"
              sizes={isMobile ? '100vw' : '811px'}
            />
          </div>
        ))
      }
    </Carousel>
  )
}


export default React.memo(Slider)
